<template>
  <div class="toggle-btn"
  :class="{
    'active': isActive
  }"
  @click="$emit('onToggleClick', isActive)"
  >{{name}}</div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.toggle-btn {
  cursor: pointer;
  color: $black40;
  font-size: 1.2rem;
  border-radius: 16px;
  border: 1px solid $greye6;
  background: $greye6;
  padding: 7px 24px;

  &.active {
    color: white;
    background: $primary;
    border: 1px solid $primary;
  }
}
</style>
