<template>
  <div class="switchbar has-background-white">
    <div class="is-flex switch-wrapper">
      <div class="switch flex-1 is-flex justify-center align-items-center"
        v-for="(title, index) in data"
        :key="index"
        @click="onChangePage(index)">
        <div  class="_fs-16 has-text-black40 has-text-weight-medium"
        :class="{
          'has-text-secondary': currentPage === index,
          'has-text-weight-bold': currentPage === index
        }">{{title}}</div>
        <div class="indicator" v-if="currentPage === index"></div>
      </div>
    </div>
    <div class="indicator-bar"></div>
  </div>
</template>

<script>

export default {
  name: 'Switchbar',
  components: {
  },
  data () {
    return {
      currentPage: this.page
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 0
    }
  },
  methods: {
    async onChangePage (index) {
      this.currentPage = index
      this.$emit('onChangePage', index)
    }
  },
  watch: {
    page: {
      immediate: true,
      handler (newVal, oldVal) {
        this.currentPage = newVal
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.switchbar {
  .switch-wrapper {
    position: relative;
    overflow-x: auto;
    min-height: 60px;
    .switch {
      min-height: 60px;
      color: white;
      position: relative;
      color: $black;
      min-width: 80px;
      padding-bottom: 4px;
    }
    .indicator {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 2px;
      background: $secondary;
      border-radius: 2px;
    }
  }
  .indicator-bar {
    width: 100%;
    z-index: 1;
    height: 2px;
    border-radius: 2px;
    background: $light-gray;
    margin-top: -2px;
  }
}
</style>
