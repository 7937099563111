<template>
  <div class="my-coupon-page">
    <Backbar
      :title="$t('myCouponPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <Switchbar
      :data="[$t('myCouponPage.exclusive'), $t('myCouponPage.special')]"
      @onChangePage="onChangePage"
      :page="currentPage"
    />
    <div class="coupon-section has-padding-20 has-background-white">
      <div class="is-flex justify-space-between align-items-center">
        <div class="_fw-500 _fs-16 has-text-black is-flex align-items-center">
          <!-- filter -->
          <ToggleButton
            name="Date added"
            :isActive="isSortDate"
            @onToggleClick="(active) => this.isSortDate = !active"
          />
        </div>
        <div>
          <img src="@/assets/icon/filter.svg"/>
        </div>
      </div>
      <div v-if="myCoupons && myCoupons.length > 0">
        <CouponItem
          v-for="(myCoupon, index) in sortCoupons"
          :key="index"
          :name="$i18n.locale === 'th' ? myCoupon.coupon.name : myCoupon.coupon.name_en"
          :coupon-id="myCoupon.coupon.code"
          :img-path="myCoupon.coupon.thumbnail_url"
          :id="myCoupon.id"
          :badge="getBadge(myCoupon)"
          :text-button="$t('common.moreDetails')"
          @onClickMoreDetails="onClickMoreDetails(myCoupon.id)"
         />
      </div>
      <div class="has-text-centered _fs-12 has-margin-top-10" v-else>{{$t('common.noData')}}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import Backbar from '@/components/Backbar.vue'

import CouponItem from '@/components/coupon/CouponItem.vue'
import Switchbar from '@/components/Switchbar.vue'

import coupons from '@/mock/coupon'
import ToggleButton from '@/components/base/ToggleButton.vue'
export default {
  name: 'MyCoupon',
  components: {
    Backbar,
    CouponItem,
    Switchbar,
    ToggleButton
  },
  data () {
    return {
      coupons,
      currentPage: 0,
      isSortDate: false
    }
  },
  computed: {
    ...mapState('Coupon', ['myCoupons']),
    sortCoupons () {
      return [...this.myCoupons].sort((a, b) => this.isSortDate ? new Date(b.created_at) - new Date(a.created_at) : new Date(a.created_at) - new Date(b.created_at))
    }
  },
  methods: {
    ...mapActions({
      fetchMyCoupon: 'Coupon/fetchMyCoupon'
    }),
    ...mapWaitingActions('Coupon', {
      fetchMyCoupon: 'app loading'
    }),
    async onChangePage (index) {
      if (index === 0) {
        this.$router.replace({
          name: 'MyCoupon',
          query: { collectMethod: 'exclusive' }
        })
      } else if (index === 1) {
        this.$router.replace({
          name: 'MyCoupon',
          query: { collectMethod: 'special' }
        })
      }
    },
    getBadge (coupon) {
      const collectMethod = this.$route.query.collectMethod || 'exclusive'
      if (collectMethod === 'exclusive') {
        return this.$t('common.level', { level: `${(coupon && coupon.member_card && coupon.member_card.name) || ''}` })
      } else {
        return (coupon && coupon.collect_method) || ''
      }
    },
    onClickMoreDetails (id) {
      const collectMethod = this.$route.query.collectMethod || 'exclusive'
      this.$router.push({ name: 'CouponDetail', params: { id: id }, query: { collectMethod } })
    }
  },
  async mounted () {
    const collectMethod = this.$route.query.collectMethod || 'exclusive'
    this.currentPage = collectMethod === 'exclusive' ? 0 : 1
    await this.fetchMyCoupon({
      collect_method: collectMethod
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.switch-wrapper {
  position: relative;
  overflow-x: auto;
  min-height: 60px;
  .switch {
    min-height: 60px;
    color: white;
    position: relative;
    color: $black;
    min-width: 80px;
    padding-bottom: 4px;
  }
  .indicator {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 2px;
    background: $light-green;
    border-radius: 2px;
  }
}
.indicator-bar {
  width: 100%;
  z-index: 1;
  height: 2px;
  border-radius: 2px;
  background: $light-gray;
  margin-top: -2px;
}
</style>
